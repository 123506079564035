import {useEffect, useState} from 'react';
import {translate, useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';

import {CollectionDto} from '../../../core/apis/api-strapi/dtos/collection-dto';
import {TeamDto} from '../../../core/apis/api-strapi/gateways/team-gateway/dtos/team-dto';
import {TeamGateway} from '../../../core/apis/api-strapi/gateways/team-gateway/team-gateway';
import {MenuNames} from '../../../core/enums/menu-names';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import TeamCard from '../../components/team-card';
import Title from '../../components/title';
import {colors} from '../../styles/global';
import * as S from './style';

const Team = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [team, setTeam] = useState<CollectionDto<TeamDto>[]>([]);

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  useEffect(() => {
    dispatch(goToPage(MenuNames.TEAM));

    window.scrollTo(0, 0);

    TeamGateway.make(locale)
      .find()
      .then(team => {
        setTeam(team);
      });
  }, []);

  return (
    <S.TeamPage>
      <S.TeamPageContent>
        <S.TeamHeaderSection>
          <Title
            title={t('team.title')}
            labelColor={colors.white}
            dividerColor={colors.darkBlue}
          />
        </S.TeamHeaderSection>

        <S.TeamContentSection>
          {team.map((collection, index) => (
            <TeamCard
              occupation={collection.attributes.occupation}
              key={index}
              name={collection.attributes.name}
              description={collection.attributes.description}
              image={collection.attributes.image}
              imageMask={collection.attributes.imageMask}
              socialMedias={collection.attributes.socialMedias}
              email={collection.attributes.email}
            />
          ))}
        </S.TeamContentSection>
      </S.TeamPageContent>
    </S.TeamPage>
  );
};

export default translate()(Team);

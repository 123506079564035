import {translate, useTranslate} from 'react-polyglot';

import {applyBreakline} from '../../../../../core/services/text-paragraph';
import {images} from '../../../../styles/global';
import {CourseTargetProps} from './props';

const CourseTarget = (props: CourseTargetProps) => {
  const t = useTranslate();

  return (
    <div className="mx-auto">
      <div className="h-full flex flex-col items-center max-w-[1048px] lg:center lg:justify-between">
        <div className="flex flex-col md:flex-row gap-4 text-center">
          {[
            {
              name: 'citizens',
              image: images.pages.courseDetails.smallCard.citizens,
              label: t('courseDetail.smallCards.citizens'),
              active: props.targetCards.includes('citizens'),
            },
            {
              name: 'students',
              image: images.pages.courseDetails.smallCard.students,
              label: t('courseDetail.smallCards.students'),
              active: props.targetCards.includes('students'),
            },
            {
              name: 'professionals',
              image: images.pages.courseDetails.smallCard.professionals,
              label: t('courseDetail.smallCards.professionals'),
              active: props.targetCards.includes('professionals'),
            },
            {
              name: 'activists',
              image: images.pages.courseDetails.smallCard.activists,
              label: t('courseDetail.smallCards.activists'),
              active: props.targetCards.includes('activists'),
            },
            {
              name: 'publicManagers',
              image: images.pages.courseDetails.smallCard.publicManagers,
              label: t('courseDetail.smallCards.publicManagers'),
              active: props.targetCards.includes('publicManagers'),
            },
          ]
            .filter(target => target.active)
            .map((target, index) => (
              <div
                key={index}
                className={
                  'w-40 h-40 bg-[#657096] text-white rounded-xl border-2 border-white mb-9'
                }
              >
                <div className="flex items-center flex-col">
                  <div className="mt-5">
                    <img
                      width={80}
                      height={81}
                      src={target.image}
                      alt={target.label}
                    />
                  </div>
                  <div className="font-justSans font-normal">
                    {target.label}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <p className="font-justSans text-base text-white font-normal leading-7 text-left">
          {applyBreakline(props.target)}
        </p>
      </div>
    </div>
  );
};

export default translate()(CourseTarget);

import styled from 'styled-components';

import {colors} from '../../styles/global';

export const CoursesGalleryWrapper = styled.div`
  width: 100%;
  margin: 75px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

export const CoursesGallery = styled.div`
  width: 90%;
  max-width: 1152px;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .alice-carousel__dots-item {
    background-color: ${colors.lightBlue};
  }

  .alice-carousel__dots-item.__active {
    background-color: ${colors.orange};
  }
`;

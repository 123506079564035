import {faArrowUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {translate, useTranslate} from 'react-polyglot';
import {useSelector} from 'react-redux';

import {RootState} from '../../../infrastructure/redux/store';
import {images} from '../../styles/global';
import ContactUs from '../contact-us';
import SocialMedia from '../social-media';
import * as S from './style';

const Footer = () => {
  const {company} = useSelector((state: RootState) => state.company);

  const t = useTranslate();

  const focusMenuRef = () => {
    document
      .getElementById('menu-anchor')
      ?.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <S.Footer>
      <S.FooterWaves id={'footer-anchor'} />

      <S.FooterUpButtom onClick={() => focusMenuRef()}>
        <FontAwesomeIcon icon={faArrowUp} />
      </S.FooterUpButtom>

      <S.FooterContent>
        <S.FooterContactDescription>
          <h1>{t('footer.description.title')}</h1>
          <S.FooterTitleDivider />
          <p>{t('footer.description.description')}</p>
          <S.FooterSocialMedias>
            {company.socialMedias?.map((socialMedia, index) => (
              <SocialMedia
                key={index}
                name={socialMedia.name}
                url={socialMedia.url}
              />
            ))}
          </S.FooterSocialMedias>
        </S.FooterContactDescription>

        <ContactUs />
      </S.FooterContent>

      <S.FooterEnd>
        <img
          width={1024}
          height={185}
          alt="footer-logo"
          src={images.components.footer.logo}
        />
        <p>{t('footer.copyright')}</p>
      </S.FooterEnd>
    </S.Footer>
  );
};

export default translate()(Footer);

import {translate} from 'react-polyglot';

import {TitleProps} from './props';
import * as S from './style';

const Title = (props: TitleProps) => {
  return (
    <S.Title labelColor={props.labelColor}>
      <h1>{props.title}</h1>
      <S.TitleDivider dividerColor={props.dividerColor} />
    </S.Title>
  );
};

export default translate()(Title);

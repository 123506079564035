import {faRotate} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ChangeEvent, FormEvent, useState} from 'react';
import {translate, useTranslate} from 'react-polyglot';

import {ContactUsGateway} from '../../../core/apis/api-frm/gateways/contact-us-gateway';
import {InitialFooterState} from './state';
import * as S from './style';

const ContactUs = () => {
  const [sending, setSending] = useState(InitialFooterState.sending);

  const [fields, setFields] = useState(InitialFooterState.fields);

  const t = useTranslate();

  const handleChange = (
    field: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();

    setFields({
      ...fields,
      [field]: e.target.value,
    });
  };

  const submit = async (): Promise<void> => {
    try {
      await ContactUsGateway.make().sendEmail({
        name: fields.name,
        fromEmail: fields.email,
        message: fields.message,
      });

      setFields(InitialFooterState.fields);

      window.confirm(t('footer.contactUs.confirmMessage'));
    } catch (error) {
      console.error(error);
    } finally {
      setSending(false);
    }
  };

  const submitEmail = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSending(true);

    await submit();
  };

  return (
    <S.ContactUs>
      <form onSubmit={submitEmail}>
        <input
          value={fields.name}
          onChange={e => handleChange('name', e)}
          required
          type="text"
          placeholder={t('footer.contactUs.labels.name')}
        />

        <input
          value={fields.email}
          onChange={e => handleChange('email', e)}
          required
          type="email"
          placeholder={t('footer.contactUs.labels.email')}
        />

        <textarea
          value={fields.message}
          onChange={e => handleChange('message', e)}
          required
          placeholder={t('footer.contactUs.labels.message')}
        />

        <S.ContactUsButton disabled={sending} type="submit">
          {sending ? (
            <FontAwesomeIcon icon={faRotate} />
          ) : (
            t('footer.contactUs.submitButton')
          )}
        </S.ContactUsButton>
      </form>
    </S.ContactUs>
  );
};

export default translate()(ContactUs);

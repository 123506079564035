import styled from 'styled-components';

import {colors, fonts, images, layers} from '../../styles/global';

export const HomePage = styled.div`
  position: relative;
`;

export const HomeWaves = styled.div`
  width: 100%;
  background-image: url(${images.pages.home.waves});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 265px;
  margin-top: -260px;

  @media (max-width: 1024px) {
    height: 74px;
    margin-top: -100px;
    background-image: url(${images.pages.home.wavesMobile});
  }
`;

export const HomePageContent = styled.div`
  margin-top: -2px;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  overflow: hidden;
  position: relative;
  z-index: ${layers.texture};
  background: linear-gradient(${colors.darkBlue}, ${colors.softBlue});
`;

export const HomeSubscriptionAlert = styled.div`
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 30px;

  p {
    text-align: center;
    font-family: ${fonts.justSans};
    font-size: 24px;
    color: ${colors.white};
  }
`;

import {translate} from 'react-polyglot';

const CoursePrice = (props: {
  workload: string;
  nextClassDate: string;
  title: string;
  description: string;
  action: string;
  actionUrl: string;
}) => {
  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center lg:flex-row lg:items-center lg:mt-10">
        <div className="h-full flex flex-col items-center max-w-lg mt-0 lg:items-start lg:justify-between lg:ml-14 lg:mt-0">
          <p className="font-justSans text-base text-white font-semibold leading-7 text-center lg:text-left mb-3">
            {props.workload}
            {props.workload && <br />}
            {props.nextClassDate}
            {props.nextClassDate && <br />}
            {props.title}
          </p>

          <p className="font-justSans text-base text-white font-semibold leading-7 text-center lg:text-left">
            <span className="font-semibold lg:text-4xl text-2xl">
              {props.description}
            </span>
          </p>
        </div>
        <div className="h-full flex flex-col items-center max-w-lg mt-5 lg:items-start lg:justify-between lg:ml-14 lg:mt-0">
          <a
            target="_blank"
            href={props.actionUrl}
            className="bg-white text-darkerBlue py-[9px] px-4 rounded-lg font-justSansBold"
          >
            {props.action}
          </a>
        </div>
      </div>
    </div>
  );
};

export default translate()(CoursePrice);

import styled from 'styled-components';

import {colors, fonts, layers} from '../../../../styles/global';

export const SmallCard = styled.div`
  width: 160px;
  height: 166px;
  background: ${colors.lightBlue};
  border: 2px solid ${colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  transition: 0.8s;
  cursor: pointer;
  z-index: ${layers.float};

  &:hover {
    background: ${colors.darkBlue};
  }

  p {
    font-family: ${fonts.justSans};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${colors.white};
    width: 140px;
  }
`;

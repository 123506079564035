import {AxiosInstance} from 'axios';

import {Languages} from '../../../../enums/languages';
import {AxiosFactory} from '../../../factories/axios-factory';
import {CollectionDto} from '../../dtos/collection-dto';
import {PaginationDto} from '../../dtos/pagination-dto';
import {SingleDto} from '../../dtos/single-dto';
import {CourseDto} from './dtos/course-dto';

export class CourseGateway {
  async find(dto?: {
    ownId?: number;
    pagination?: {
      start: number;
      limit: number;
    };
  }): Promise<CollectionDto<CourseDto>[]> {
    const response = await this.axiosInstance.get<PaginationDto<CourseDto>>(
      '',
      {
        params: {
          locale: this.locale,
          populate: '*',
          sort: ['id'],
          filters: {
            id: {
              $ne: dto?.ownId ? [dto.ownId] : [],
            },
          },
          pagination: dto?.pagination,
        },
      }
    );

    return response.data.data;
  }

  async findById(id: number): Promise<CollectionDto<CourseDto>> {
    const response = await this.axiosInstance.get<SingleDto<CourseDto>>(
      `/${id.toFixed()}`,
      {
        params: {
          locale: this.locale,
          populate: '*',
        },
      }
    );

    return response.data.data;
  }

  constructor(
    private readonly axiosInstance: AxiosInstance,
    private readonly locale: Languages
  ) {}

  static make(locale: Languages): CourseGateway {
    const axiosInstance = AxiosFactory.make(
      `${process.env.REACT_APP_STRAPI_BASE_URL}/courses`
    );

    return new CourseGateway(axiosInstance, locale);
  }
}

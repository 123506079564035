import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  min-width: 120px;
  height: 36px;
  border-radius: 4px;
  color: ${colors.white};
  background: ${colors.orange};
  font-family: ${fonts.justSansBold};
  font-size: 13px;
  line-height: 18px;

  &:hover {
    background: ${colors.darkOrange};
  }
`;

import {useState} from 'react';
import {translate, useTranslate} from 'react-polyglot';

import {images} from '../../../../styles/global';
import SmallCard from '../small-card';
import {InitialWheelState} from './state';
import * as S from './style';

const Wheel = () => {
  const t = useTranslate();

  const [animate, setAnimate] = useState(InitialWheelState.animate);

  const fps = 45;

  const renderSmallCards = () => {
    return [
      {
        name: 'sustainable',
        image: images.pages.home.smallCard.sustainable,
        label: t('index.wheel.sustainable'),
      },
      {
        name: 'healthy',
        image: images.pages.home.smallCard.healthy,
        label: t('index.wheel.healthy'),
      },
      {
        name: 'mobility',
        image: images.pages.home.smallCard.mobility,
        label: t('index.wheel.mobility'),
      },
      {
        name: 'content',
        image: images.pages.home.smallCard.content,
        label: t('index.wheel.content'),
      },
      {
        name: 'audience',
        image: images.pages.home.smallCard.audience,
        label: t('index.wheel.audience'),
      },
      {
        name: 'solutions',
        image: images.pages.home.smallCard.solutions,
        label: t('index.wheel.solutions'),
      },
    ].map((card, index) => (
      <SmallCard
        key={index}
        image={card.image}
        name={card.name}
        label={card.label}
        onMouseEnter={() => setAnimate(false)}
        onMouseLeave={() => setAnimate(true)}
      />
    ));
  };

  return (
    <>
      <S.HomeWheel>
        <S.SmallCardsCircle fps={fps} animate={animate}>
          {renderSmallCards()}
        </S.SmallCardsCircle>

        <S.HomeWheelCore fps={fps} animate={animate} />
        <S.HomeWheelCircle fps={fps} animate={animate} />
      </S.HomeWheel>

      <S.HomeSmallCards>{renderSmallCards()}</S.HomeSmallCards>
    </>
  );
};

export default translate()(Wheel);

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {translate} from 'react-polyglot';

import {filterSocialMedias} from '../../../core/services/filter-social-medias';
import {SocialMediaProps} from './props';
import * as S from './style';

const SocialMedia = (props: SocialMediaProps) => {
  return (
    <S.SocialMedia>
      <a
        target="_blank"
        href={props.url}
        aria-label={`social media ${props.name}`}
      >
        <FontAwesomeIcon
          className={`${props.name} ${props.className}`}
          icon={filterSocialMedias(props.name)}
          color={props.color ? props.color : 'white'}
        />
      </a>
    </S.SocialMedia>
  );
};

export default translate()(SocialMedia);

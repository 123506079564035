import {applyBreakline} from '../../../../../core/services/text-paragraph';
import {CourseDescriptionProps} from './props';

const CoursePrerequisite = (props: CourseDescriptionProps) => {
  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center lg:flex-row lg:items-start">
        <div className="h-full flex flex-col items-center max-w-[1048px] lg:items-start lg:justify-between">
          <p className="font-justSans text-base text-white font-normal leading-7 text-left">
            {applyBreakline(props.requirement)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoursePrerequisite;

import styled from 'styled-components';

import {SocialMediaNames} from '../../../core/enums/social-media-names';
import {colors} from '../../styles/global';

export const SocialMedia = styled.div`
  .facebook:hover {
    color: ${colors[SocialMediaNames.FACEBOOK]};
  }

  .twitter:hover {
    color: ${colors[SocialMediaNames.TWITTER]};
  }

  .youtube:hover {
    color: ${colors[SocialMediaNames.YOUTUBE]};
  }

  .whatsapp:hover {
    color: ${colors[SocialMediaNames.WHATSAPP]};
  }

  .linkedin:hover {
    color: ${colors[SocialMediaNames.LINKEDIN]};
  }

  .instagram:hover {
    color: ${colors[SocialMediaNames.INSTAGRAM]};
  }

  .website:hover {
    color: ${colors[SocialMediaNames.WEBSITE]};
  }

  svg {
    transition: 0.5s;
    cursor: pointer;
    width: 32px;
    font-size: 32px;
    margin: 0 16px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

import {colors, images} from '../../styles/global';
import SocialMedia from '../social-media';
import {TeamCardProps} from './props';

const TeamCard = (props: TeamCardProps) => {
  const {occupation, name, description, image, socialMedias, email} = props;

  return (
    <div className="flex flex-col items-center mx-auto lg:flex-row lg:items-center">
      <div className="max-h-[384px] max-w-[360px] relative">
        <img
          width={380}
          height={357}
          src={image.data.attributes.url}
          alt={`team-${name}`}
          className="max-w-96 max-h-96 rounded-xl"
        />

        <img
          width={161}
          height={121}
          src={
            props.imageMask.color === 'orange'
              ? images.components.teamCard.logo
              : images.components.teamCard.logoBlue
          }
          alt={`team-${name}-mask`}
          className={`
            max-h-[35%] max-w-[35%]
            absolute ${
              props.imageMask.positionX === 'left' ? 'left-2' : 'right-2'
            } ${props.imageMask.positionY === 'bottom' ? 'bottom-2' : 'top-2'}`}
        />
      </div>
      <div className="flex flex-col items-center max-w-lg mt-14 lg:items-start lg:justify-between lg:ml-14 lg:mt-0">
        <span className="font-justSansBold uppercase text-lg text-darkBlue text-center lg:text-left">
          {occupation}
        </span>
        <strong className="font-justSansBold text-3xl text-orange mt-1 text-center lg:text-left">
          {name}
        </strong>
        <span className="font-justSans text-orange lg:text-left">{email}</span>
        <p className="font-justSans text-sm text-darkerBlue mt-4 mb-4 text-justify">
          {description}
        </p>

        <div className="flex flex-row">
          {socialMedias.map((socialMedia, index) => (
            <SocialMedia
              key={index}
              name={socialMedia.name}
              url={socialMedia.url}
              color={colors.orange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamCard;

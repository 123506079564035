import styled from 'styled-components';

import {colors, fonts, layers} from '../../styles/global';

export const CourseCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 20px;
  gap: 24px;
  width: 281px;
  height: 460px;
  background: linear-gradient(
      0deg,
      rgba(230, 232, 241, 1),
      rgba(230, 232, 241, 1)
    ),
    rgba(255, 255, 255, 1);
  border: 1px solid ${colors.white};
  box-shadow: inset 0px 0px 50px ${colors.white};
  border-radius: 12px;
  position: relative;
  z-index: ${layers.float};
  cursor: pointer;

  img {
    object-fit: cover;
    border-radius: 12px;
  }

  h1 {
    font-family: ${fonts.justSansBold};
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: ${colors.orange};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    font-family: ${fonts.justSans};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${colors.darkBlue};
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

import {applyBreakline} from '../../../../../core/services/text-paragraph';
import {images} from '../../../../styles/global';
import {CourseDescriptionProps} from './props';

const CourseContents = (props: CourseDescriptionProps) => {
  const content = props.content;
  const halfwayThrough = Math.ceil(content.length / 2);

  const arrayFirstHalf = content.slice(0, halfwayThrough);
  const arraySecondHalf = content.slice(halfwayThrough, content.length);

  return (
    <div className="mx-auto">
      <div className="flex flex-col items-center lg:flex-row lg:items-start">
        <div className="h-full flex flex-col items-center max-w-lg lg:items-start lg:justify-between lg:ml-4 mx:3">
          <div className="flex flex-col">
            {arrayFirstHalf.map((content, index) => (
              <div key={index} className="flex">
                <img
                  width={18}
                  height={22}
                  alt={`arrow-first-section-${index}`}
                  className="h-max pt-2 pr-2"
                  src={images.pages.courseDetails.arrow}
                />
                <p className="font-justSans text-base text-white font-normal leading-7 text-left lg:mb-12 mb-9">
                  {content.title}
                  <br />
                  {content.description && applyBreakline(content.description)}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="h-full flex flex-col items-center max-w-lg lg:items-start lg:justify-between lg:ml-4">
          <div className="flex flex-col">
            {arraySecondHalf.map((content, index) => (
              <div key={index} className="flex">
                <img
                  width={18}
                  height={22}
                  alt={`arrow-first-section-${index}`}
                  className="h-max pt-2 pr-2"
                  src={images.pages.courseDetails.arrow}
                />
                <p className="font-justSans text-base text-white font-normal leading-7 text-left lg:mb-12 mb-9">
                  {content.title}
                  <br />
                  {content.description && applyBreakline(content.description)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseContents;

import {useEffect, useRef, useState} from 'react';
import {translate, useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';

import {CollectionDto} from '../../../core/apis/api-strapi/dtos/collection-dto';
import {SubscriptionDto} from '../../../core/apis/api-strapi/gateways/subscription-gateway/dtos/subscription-dto';
import {SubscriptionGateway} from '../../../core/apis/api-strapi/gateways/subscription-gateway/subscription-gateway';
import {MenuNames} from '../../../core/enums/menu-names';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import Button from '../../components/button';
import CoursesGallery from '../../components/courses-gallery';
import Texture from '../../components/texture';
import Title from '../../components/title';
import {colors} from '../../styles/global';
import Banner from './components/banner';
import Wheel from './components/wheel';
import * as S from './style';

const Home = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [subscription, setSubsctiption] =
    useState<CollectionDto<SubscriptionDto>>();

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  const [height, setHeight] = useState(0);

  const textureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(goToPage(MenuNames.INDEX));

    window.scrollTo(0, 0);

    textureRef.current && setHeight(textureRef.current.clientHeight);

    SubscriptionGateway.make(locale)
      .find()
      .then(subscription => {
        setSubsctiption(subscription);
      });
  }, []);

  return (
    <S.HomePage>
      <Banner />

      <S.HomeWaves />

      <S.HomePageContent ref={textureRef}>
        <a target="_blank" href={subscription?.attributes.link}>
          <S.HomeSubscriptionAlert>
            <p>{subscription?.attributes.description}</p>
            <Button label={subscription?.attributes.button} />
          </S.HomeSubscriptionAlert>
        </a>
        <Texture height={height} />
        <Title
          title={t('index.wheel.title')}
          labelColor={colors.white}
          dividerColor={colors.orange}
        />
        <Wheel />
        <CoursesGallery
          title={t('index.courses.title')}
          labelColor={colors.white}
          dividerColor={colors.darkBlue}
        />
      </S.HomePageContent>
    </S.HomePage>
  );
};

export default translate()(Home);

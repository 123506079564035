import {useEffect, useRef, useState} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';

import {CollectionDto} from '../../../core/apis/api-strapi/dtos/collection-dto';
import {CourseGateway} from '../../../core/apis/api-strapi/gateways/course-gateway/course-gateway';
import {CourseDto} from '../../../core/apis/api-strapi/gateways/course-gateway/dtos/course-dto';
import {MenuNames} from '../../../core/enums/menu-names';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import CourseCard from '../../components/course-card';
import Texture from '../../components/texture';
import Title from '../../components/title';
import {colors} from '../../styles/global';
import * as S from './style';

const Courses = () => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [courses, setCourses] = useState<CollectionDto<CourseDto>[]>([]);

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  const [height, setHeight] = useState(0);

  const textureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(goToPage(MenuNames.COURSES));

    window.scrollTo(0, 0);

    textureRef.current && setHeight(textureRef.current.clientHeight);

    CourseGateway.make(locale)
      .find()
      .then(courses => {
        setCourses(courses);
      });
  }, []);

  return (
    <S.CoursesPage>
      <S.CoursesPageContent>
        <S.CoursesPageTexture ref={textureRef}>
          <Texture height={height} />

          <S.CoursesHeaderSection>
            <Title
              title={t('courses.title')}
              labelColor={colors.white}
              dividerColor={colors.orange}
            />
          </S.CoursesHeaderSection>

          <S.CoursesGallery>
            {courses.map((collection, index) => {
              return (
                <div className="card" key={index}>
                  <CourseCard
                    id={collection.id}
                    title={collection.attributes.title}
                    image={collection.attributes.image}
                    description={collection.attributes.description}
                  />
                </div>
              );
            })}
          </S.CoursesGallery>
        </S.CoursesPageTexture>
      </S.CoursesPageContent>
    </S.CoursesPage>
  );
};

export default Courses;

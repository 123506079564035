import styled from 'styled-components';

import {images} from '../../styles/global';

export const LogoTexture = styled.div<{
  index: number;
  scale: number;
  left: boolean;
}>`
  position: absolute;
  ${props => (props.left ? 'left: 15px;' : 'right: 15px;')}
  top: ${props => props.index * 400}px;
  background-image: url(${images.pages.home.logoTexture});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: ${props => props.scale * 298}px;
  height: ${props => props.scale * 237}px;
  z-index: -2;

  @media (max-width: 768px) {
    width: ${props => props.scale * 0.5 * 298}px;
    height: ${props => props.scale * 0.5 * 237}px;
    ${props => (props.left ? 'left: 4px;' : 'right: 4px;')}
  }
`;

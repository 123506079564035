import styled from 'styled-components';

import {colors, fonts, images} from '../../../../styles/global';

export const BannerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${images.pages.home.banner}) no-repeat center 20%;
  background-size: cover;
  min-height: 800px;

  @media (min-width: 1680px) {
    background-size: cover;
    min-height: 900px;
  }

  @media (max-width: 1024px) {
    min-height: 600px;
  }
`;

export const BannerContent = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: ${fonts.justSansBold};
    font-size: 4vw;
    line-height: 5vw;
    text-align: center;
    color: ${colors.white};
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 65%;
    margin-top: 170px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      margin-top: 150px;
      width: 90%;
      font-size: 6vw;
      line-height: 8vw;
    }

    @media (max-width: 425px) {
      width: 90%;
      font-size: 10vw;
      line-height: 12vw;
    }

    @media screen and (min-width: 2560px) {
      font-size: 68px;
      line-height: 86px;
    }
  }
`;

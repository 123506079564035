import styled from 'styled-components';

import {colors, fonts} from '../../styles/global';

export const ContactUs = styled.div`
  height: 362px;
  width: 548px;

  @media (max-width: 768px) {
    width: 100%;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-family: ${fonts.justSans};
    font-size: 16px;
    line-height: 21px;

    @media (max-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 769px) {
      padding-left: 100px;
    }
  }

  input,
  textarea {
    width: 100%;
    margin: 10px 0;
    color: ${colors.white};
    border-radius: 5px;
    padding: 7px 13px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
  }

  textarea {
    min-height: 100px;
    resize: none;
  }
`;

export const ContactUsButton = styled.button`
  @media (max-width: 1024px) {
    width: 100%;
  }

  margin-top: 10px;
  background-color: ${colors.orange};
  width: 78px;
  height: 36px;
  color: ${colors.white};
  border-radius: 8px;
  border: none;
  font-family: ${fonts.justSansBold};
  font-size: 14px;
  line-height: 18px;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${colors.darkOrange};
  }

  &:disabled {
    background-color: ${colors.darkOrange};
  }

  svg {
    width: 20px;
    font-size: 20px;
    color: ${colors.white};
    animation: spin infinite 5s linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

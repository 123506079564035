import styled from 'styled-components';

import {colors, fonts, images, layers} from '../../styles/global';

export const Menu = styled.header`
  position: relative;
  z-index: ${layers.menu};
`;

export const MenuItemsWrapper = styled.div`
  @media (max-width: 1024px) {
    padding: 0 5%;
    height: 100px;
  }

  @media (min-width: 1025px) {
    background-image: url(${images.components.menu.bg});
    svg {
      display: none;
    }
    padding: 0 80px;
  }

  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: -50px;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${layers.menu};

  svg {
    cursor: pointer;
    width: 20px;
    font-size: 20px;
    margin: 20px;
    transition: 1s linear;
  }
`;

export const Logo = styled.div`
  margin-bottom: 20px;
  width: 243px;

  @media (max-width: 1024px) {
    width: 206px;
  }
`;

export const MenuItems = styled.nav`
  @media (max-width: 1024px) {
    display: none;
  }

  height: 22px;
  width: 413px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const MenuItem = styled('div')<{open: boolean}>`
  cursor: pointer;
  transition: 0.5s;
  transition-delay: 250ms;

  @media (max-width: 1024px) {
    opacity: ${props => {
      return props.open ? 1 : 0;
    }};
    transition: ${props => {
      return props.open ? 'opacity 1s linear' : 'opacity 0.1s linear';
    }};
  }
`;

export const MenuItemContent = styled.div<{active: boolean}>`
  font-family: ${fonts.justSans};
  font-size: 16px;
  color: ${props => {
    return props.active ? colors.orange : colors.darkBlue;
  }};
  line-height: 21px;
  transition: 0.2s;

  &:hover {
    color: ${colors.orange};
  }
`;

export const MenuUnderline = styled('div')<{active: boolean}>`
  width: 100%;
  height: 2px;
  background: ${colors.darkBlue};
  border-radius: 2px 2px 0px 0px;
  opacity: ${props => {
    return props.active ? 1 : 0;
  }};
  transition: 0.2s;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MenuItemsResponsive = styled('nav')<{open: boolean}>`
  @media (min-width: 1025px) {
    display: none;
  }

  padding: 100px 5% 40px;
  height: 340px;
  background-image: url(${images.components.menu.bgMobile});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: fixed;
  top: ${props => {
    return props.open ? 0 : '-229px';
  }};
  transition: 0.2s;
`;

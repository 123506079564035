import {translate, useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {MenuNames} from '../../../core/enums/menu-names';
import {lazyLoad} from '../../../core/services/img-lazy-load';
import {redirect} from '../../../core/services/link-routes';
import Button from '../button';
import {CourseCardProps} from './props';
import * as S from './style';

const CourseCard = (props: CourseCardProps) => {
  const t = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        redirect({
          history,
          dispatch,
          route: MenuNames.COURSE_DETAIL,
          queries: [props.id],
        });
      }}
    >
      <S.CourseCard>
        <img
          width={458}
          height={337}
          alt={`course-${props.id}`}
          src={lazyLoad(props.image.data)}
        />
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        <Button label={t('courses.courseButton')} />
      </S.CourseCard>
    </div>
  );
};

export default translate()(CourseCard);

import styled from 'styled-components';

import {images} from '../../../../styles/global';

export const HomeSmallCards = styled.div`
  @media (min-width: 768px) {
    display: none;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  > * {
    margin: 10px 0;
  }
`;

export const HomeWheel = styled.div`
  margin-top: 75px;
  width: 688px;
  height: 707px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const HomeWheelCore = styled.div<{fps: number; animate: boolean}>`
  background-image: url(${images.pages.home.wheelCore});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: 257px;
  height: 257px;
  animation: ${props => props.fps}s ease-in-out infinite spin-reverse;
  ${props => !props.animate && 'animation-play-state: paused;'}

  @keyframes spin-reverse {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

export const HomeWheelCircle = styled.div<{fps: number; animate: boolean}>`
  background-image: url(${images.pages.home.wheelCircle});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 566px;
  height: 566px;
  position: absolute;
  animation: ${props => props.fps}s ease-in-out infinite spin;
  ${props => !props.animate && 'animation-play-state: paused;'}
  z-index: -1;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const SmallCardsCircle = styled.div<{fps: number; animate: boolean}>`
  position: relative;
  width: 713px;
  height: 713px;
  animation: ${props => props.fps}s ease-in-out infinite spin;
  ${props => !props.animate && 'animation-play-state: paused;'}

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  > * {
    animation: ${props => props.fps}s ease-in-out infinite spin-reverse;
    ${props => !props.animate && 'animation-play-state: paused;'}

    @keyframes spin-reverse {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
    }
    &:first-child {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform-origin: center center;
    }

    &:nth-child(2) {
      position: absolute;
      top: 150px;
      right: 0;
      transform-origin: center center;
    }

    &:nth-child(3) {
      position: absolute;
      top: 400px;
      right: 0;
      transform-origin: center center;
    }

    &:nth-child(4) {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      transform-origin: center center;
    }

    &:nth-child(5) {
      position: absolute;
      bottom: 140px;
      left: 0;
      transform-origin: center center;
    }

    &:nth-child(6) {
      position: absolute;
      left: 0;
      top: 150px;
      transform-origin: center center;
    }
  }
`;

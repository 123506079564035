import styled from 'styled-components';

import {colors, layers} from '../../styles/global';

export const CoursesPage = styled.div`
  position: relative;
  padding-top: 75px;
`;

export const CoursesPageContent = styled.div`
  overflow: hidden;
  background: linear-gradient(${colors.darkBlue}, ${colors.softBlue});
  padding-top: 100px;
`;

export const CoursesPageTexture = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: ${layers.texture};
  position: relative;
  min-height: 100vh;
`;

export const CoursesHeaderSection = styled.section`
  height: 228px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;

  @media (min-width: 1024px) {
    height: 308px;
  }
`;

export const CoursesGallery = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: -25px;
  margin-bottom: 300px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }
`;

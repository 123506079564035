import styled from 'styled-components';

import {colors, images, layers} from '../../styles/global';

export const DetailsPage = styled.div`
  position: relative;
  padding-top: 75px;
`;

export const DetailsPageContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: ${layers.texture};
  background: rgb(229, 232, 243);
  background: linear-gradient(
    180deg,
    rgba(229, 232, 243, 1) 0%,
    rgba(210, 214, 229, 1) 50%,
    rgba(25, 39, 95, 1) 70%,
    rgba(25, 39, 95, 1) 100%
  );
`;

export const DetailsHeaderSection = styled.section`
  height: 308px;
  background-image: url(${images.components.titleWaves.bgMobile});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 56px;

  @media screen and (min-width: 1024px) {
    background-image: url(${images.components.titleWaves.bg});
  }
`;

export const DetailsContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 56px;
  padding: 0px 24px;
  gap: 48px;
`;

export const DetailsFooterSection = styled.section`
  height: 67px;
  background-image: url(${images.pages.courseDetails.footerWaves.bgMobile});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1024px) {
    margin-top: -1px;
    height: 245px;
    background-image: url(${images.pages.courseDetails.footerWaves.bg});
  }
`;

export const DetailsUnderpage = styled.div`
  position: relative;
  padding-bottom: 100px;
  background: linear-gradient(
    180deg,
    ${colors.softerBlue} 0%,
    ${colors.softBlue} 82.35%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TeamSection = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 0px 24px;
`;

import 'react-alice-carousel/lib/alice-carousel.css';

import {useEffect} from 'react';
import {I18n} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {MenuNames} from '../../core/enums/menu-names';
import {WcsLabelFactory} from '../../infrastructure/i18n/repositories/wcs-label-factory';
import {ApisInitialState} from '../../infrastructure/redux/apis-initial-state';
import {AppDispatch, RootState} from '../../infrastructure/redux/store';
import Footer from '../components/footer';
import Loader from '../components/loader';
import Menu from '../components/menu';
import CourseDetail from '../pages/course-detail';
import Courses from '../pages/courses';
import Home from '../pages/home';
import Team from '../pages/team';

const Layout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  useEffect(() => {
    ApisInitialState(locale, dispatch);

    window.history.scrollRestoration = 'manual';
  }, []);

  return (
    <I18n locale={locale} messages={WcsLabelFactory.make(locale)}>
      <>
        <Router>
          <Loader>
            <Menu />

            <Switch>
              <Route path="/" exact render={() => <Home />} />

              <Route
                path={`/${MenuNames.COURSES}`}
                exact
                render={() => <Courses />}
              />

              <Route
                path={`/${MenuNames.COURSE_DETAIL}/:id`}
                exact
                render={() => <CourseDetail />}
              />

              <Route
                path={`/${MenuNames.TEAM}`}
                exact
                render={() => <Team />}
              />

              <Redirect from="*" to="/" />
            </Switch>

            <Footer />
          </Loader>
        </Router>
      </>
    </I18n>
  );
};

export default Layout;

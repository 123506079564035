import {lazyLoad} from '../../../../../core/services/img-lazy-load';
import {applyBreakline} from '../../../../../core/services/text-paragraph';
import {CourseDescriptionProps} from './props';
import * as S from './style';

const CourseDescription = (props: CourseDescriptionProps) => {
  return (
    <S.CourseDescription>
      <S.CourseDescriptionImage>
        <S.CourseDescriptionImageMask />
        <img
          width={458}
          height={337}
          className="course-image-cover"
          src={lazyLoad(props.image.data)}
          alt="course-image-cover"
        />
      </S.CourseDescriptionImage>

      <S.CourseDescriptionText>
        <p>{applyBreakline(props.description)}</p>
      </S.CourseDescriptionText>
    </S.CourseDescription>
  );
};

export default CourseDescription;

import {MenuNames} from '../../../core/enums/menu-names';
import {I18n} from '../interfaces/i18n';
import {WcsLabelDto} from './wcs-label-dto';

export class PtBrRepository implements I18n<WcsLabelDto> {
  translate(): WcsLabelDto {
    return {
      languages: {
        portuguese: 'Português',
      },
      menu: {
        [MenuNames.INDEX]: 'Home',
        [MenuNames.COURSES]: 'Cursos',
        [MenuNames.COURSE_DETAIL]: 'Detalhe do curso',
        [MenuNames.TEAM]: 'Equipe',
        [MenuNames.CONTACT]: 'Contato',
      },
      footer: {
        description: {
          title: 'Fale Conosco',
          description:
            'Entre em contato com a nossa equipe através do formulário ou das nossas redes sociais.',
        },
        contactUs: {
          labels: {
            name: 'Nome',
            email: 'Email',
            message: 'Mensagem',
          },
          submitButton: 'Enviar',
          confirmMessage: 'Obrigado por enviar sua mensagem!',
        },
        copyright: 'Copyright © . Todos os direitos reservados',
      },
      index: {
        banner: {
          title: 'Educando para a construção de cidades melhores',
          button: 'Saiba mais',
        },
        wheel: {
          title: 'Vantagens da plataforma',
          sustainable: 'Construção de cidades mais sustentáveis',
          healthy: 'Construção de cidades mais saudáveis',
          mobility: 'Visão sistêmica da mobilidade urbana',
          content: 'Acesso ao conteúdo de qualquer lugar a qualquer momento',
          audience: 'Conteúdo acessível a diferentes públicos',
          solutions: 'Embasamento técnico das soluções',
        },
        courses: {
          title: 'Conheça os nossos cursos',
          button: 'Ver todos os cursos',
        },
      },
      team: {
        title: 'Conheça nossa equipe',
      },
      courses: {
        title: 'Conheça os nossos cursos',
        moreCourses: 'Conheça também',
        courseButton: 'Saiba mais',
      },
      courseDetail: {
        description: {
          learn: 'O que você vai aprender',
          target: 'Para quem é este curso',
          content: 'Conteúdo',
          requirements: 'Pré-requisitos',
        },
        smallCards: {
          citizens: 'Cidadãos',
          students: 'Estudantes',
          professionals: 'Profissionais',
          activists: 'Ativistas',
          publicManagers: 'Gestores Públicos',
        },
        team: {
          teamButton: 'Conheça nossa equipe',
        },
        courses: {
          courseButton: 'Conheça também',
        },
      },
    };
  }
}

import {useEffect, useState} from 'react';
import AliceCarousel from 'react-alice-carousel';
import {translate, useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {CollectionDto} from '../../../core/apis/api-strapi/dtos/collection-dto';
import {CourseGateway} from '../../../core/apis/api-strapi/gateways/course-gateway/course-gateway';
import {CourseDto} from '../../../core/apis/api-strapi/gateways/course-gateway/dtos/course-dto';
import {MenuNames} from '../../../core/enums/menu-names';
import {redirect} from '../../../core/services/link-routes';
import {RootState} from '../../../infrastructure/redux/store';
import Button from '../button';
import CourseCard from '../course-card';
import Title from '../title';
import {CoursesGalleryProps} from './props';
import * as S from './style';

const CoursesGallery = (props: CoursesGalleryProps) => {
  const t = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const [courses, setCourses] = useState<CollectionDto<CourseDto>[]>([]);

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  useEffect(() => {
    CourseGateway.make(locale)
      .find({
        ownId: props.ownId,
        pagination: {
          start: 0,
          limit: 6,
        },
      })
      .then(courses => {
        setCourses(courses);
      });
  }, [props.ownId]);

  return (
    <S.CoursesGalleryWrapper>
      {courses.length > 0 && (
        <>
          <Title
            title={props.title}
            labelColor={props.labelColor}
            dividerColor={props.dividerColor}
          />

          <S.CoursesGallery>
            <AliceCarousel
              disableButtonsControls
              responsive={{
                0: {items: 1},
                568: {items: 2},
                1024: {items: 3},
              }}
              items={courses.map((collection, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    onDragStart={e => e.preventDefault()}
                    role="presentation"
                  >
                    <CourseCard
                      id={collection.id}
                      title={collection.attributes.title}
                      image={collection.attributes.image}
                      description={collection.attributes.description}
                    />
                  </div>
                );
              })}
            />
          </S.CoursesGallery>
        </>
      )}

      <div
        onClick={() => {
          redirect({
            history,
            dispatch,
            route: MenuNames.COURSES,
          });
        }}
      >
        <Button label={t('index.courses.button')} />
      </div>
    </S.CoursesGalleryWrapper>
  );
};

export default translate()(CoursesGallery);

import {configureStore} from '@reduxjs/toolkit';

import {companySliceReducer} from './slices/apis/company-slice';
import {localeSliceReducer} from './slices/locale-slice';
import {menuSliceReducer} from './slices/menu-slice';

export const store = configureStore({
  reducer: {
    locale: localeSliceReducer,
    menu: menuSliceReducer,
    company: companySliceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

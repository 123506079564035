import {translate} from 'react-polyglot';

import {TextureProps} from './props';
import * as S from './style';

const Texture = (props: TextureProps) => {
  const renderLogoTextures = () => {
    const logoHeight = 237;

    const total = props.height / logoHeight;

    const components = [];

    for (let i = 0; i < total; i++) {
      const random = Math.random() < 0.5;
      const scale = random ? 0.5 : 0.4;
      const left = i % 2 === 0;

      components.push(
        <S.LogoTexture key={i} index={i} scale={scale} left={!left} />
      );
    }

    return components;
  };

  return <>{renderLogoTextures()}</>;
};

export default translate()(Texture);

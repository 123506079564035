import styled from 'styled-components';

import {colors, fonts} from '../../../../styles/global';

export const CourseDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 100px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
    gap: 50px;
  }
`;

export const CourseDescriptionImage = styled.div`
  position: relative;

  .course-image-cover {
    border-top-left-radius: 30%;
    border-bottom-right-radius: 30%;
    min-width: 458px;

    @media (max-width: 530px) {
      min-width: 90%;
    }
  }
`;

export const CourseDescriptionImageMask = styled.div`
  position: absolute;
  left: 5%;
  right: -5%;
  top: 5%;
  bottom: -5%;
  margin: auto;
  border: 3px solid ${colors.orange};
  border-top-left-radius: 30%;
  border-bottom-right-radius: 30%;
`;

export const CourseDescriptionText = styled.div`
  @media (max-width: 1024px) {
    max-width: 100%;
  }

  max-width: 400px;

  p {
    font-family: ${fonts.justSans};
    font-size: 16px;
    color: ${colors.darkBlue};
  }
`;

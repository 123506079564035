import {translate} from 'react-polyglot';

import {ButtonProps} from './props';
import * as S from './style';

const Button = (props: ButtonProps) => {
  return <S.Button>{props.label}</S.Button>;
};

export default translate()(Button);

import styled from 'styled-components';

import {colors, images} from '../../styles/global';

export const TeamPage = styled.div`
  position: relative;
  padding-top: 75px;
`;

export const TeamPageContent = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    ${colors.softerBlue} 0%,
    ${colors.softBlue} 82.35%
  );
  padding-bottom: 100px;

  @media screen and (min-width: 1024px) {
    padding-bottom: 200px;
  }
`;

export const TeamHeaderSection = styled.section`
  height: 228px;
  background-image: url(${images.components.titleWaves.bgMobile});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1024px) {
    height: 308px;
    background-image: url(${images.components.titleWaves.bg});
  }
`;

export const TeamContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  margin-top: 48px;
  padding: 0px 24px;

  @media screen and (min-width: 1024px) {
    padding: 0px;
  }
`;

import {translate} from 'react-polyglot';

import {SmallCardProps} from './props';
import * as S from './style';

const SmallCard = (props: SmallCardProps) => {
  return (
    <S.SmallCard
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave()}
    >
      <img
        alt={`icon-${props.name}`}
        width={80}
        height={80}
        src={props.image}
      />
      <p>{props.label}</p>
    </S.SmallCard>
  );
};

export default translate()(SmallCard);

import {translate, useTranslate} from 'react-polyglot';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {MenuNames} from '../../../../../core/enums/menu-names';
import {redirect} from '../../../../../core/services/link-routes';
import Button from '../../../../components/button';
import * as S from './style';

const Banner = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <S.BannerWrapper>
      <S.BannerContent>
        <h1>{t('index.banner.title')}</h1>
        <div
          onClick={() => {
            redirect({history, dispatch, route: MenuNames.COURSES});
          }}
        >
          <Button label={t('index.banner.button')} />
        </div>
      </S.BannerContent>
    </S.BannerWrapper>
  );
};

export default translate()(Banner);

import styled from 'styled-components';

import {colors, fonts, images} from '../../styles/global';

export const Footer = styled.footer`
  position: relative;
  background-image: url(${images.components.footer.bg});
  padding: 10px 0;
  bottom: 0;
  background-color: ${colors.darkBlue};
  height: 741px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const FooterWaves = styled.div`
  width: 100%;
  background-image: url(${images.components.footer.waves});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 265px;
  margin-top: -150px;

  @media (max-width: 768px) {
    height: 145px;
    margin-top: -70px;
    background-image: url(${images.components.footer.wavesMobile});
  }

  @media (max-width: 425px) {
    height: 74px;
    margin-top: -50px;
    background-image: url(${images.components.footer.wavesMobile});
  }
`;

export const FooterContent = styled.div`
  max-width: 1280px;
  width: 80%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterTitleDivider = styled.div`
  width: 85px;
  height: 6px;
  background-color: ${colors.orange};
  margin-bottom: 6px;
`;

export const FooterContactDescription = styled.div`
  width: 321px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    margin-top: 50px;
    align-items: center;
    width: 100%;
  }

  h1 {
    font-family: ${fonts.justSansBold};
    font-size: 36px;
    line-height: 47px;
    color: ${colors.white};
    margin-bottom: 5px;
  }

  p {
    font-family: ${fonts.justSans};
    font-size: 14px;
    line-height: 18px;
    color: ${colors.white};
    margin: 30px 0;
  }
`;

export const FooterSocialMedias = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 332px;
  height: 120px;
  justify-content: space-between;

  @media (max-width: 1024px) {
    margin-top: 100px;
    width: 275px;
    padding: 0 5px;
  }

  p {
    color: ${colors.white};
    font-family: ${fonts.justSans};
    font-size: 12px;
    line-height: 18px;
  }
`;

export const FooterUpButtom = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${colors.white};
  opacity: 0.3;
  position: absolute;
  right: 20px;
  top: 60px;
  border-radius: 2px;
  cursor: pointer;

  @media (max-width: 1024px) {
    top: 30px;
  }

  svg {
    margin: 5px auto;
    width: 30px;
    height: 20px;
    font-size: 15px;
  }
`;

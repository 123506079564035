import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {translate, useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {MenuNames} from '../../../core/enums/menu-names';
import {redirect} from '../../../core/services/link-routes';
import {
  closeMenu,
  toggleMenu,
} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import {colors, images} from '../../styles/global';
import * as S from './style';

const Menu = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const {currentPage, open} = useSelector((state: RootState) => state.menu);

  const isCurrentPage = (menuItem: string[]): boolean => {
    return menuItem.includes(currentPage);
  };

  const focusFooterRef = () => {
    document
      .getElementById('footer-anchor')
      ?.scrollIntoView({behavior: 'smooth'});
  };

  const renderMenuItems = () => {
    return (
      <>
        <S.MenuItem
          onClick={() => {
            redirect({history, dispatch, route: MenuNames.INDEX});
          }}
          open={open}
        >
          <S.MenuItemContent active={isCurrentPage([MenuNames.INDEX])}>
            {t(`menu.${MenuNames.INDEX}`)}
            <S.MenuUnderline active={isCurrentPage([MenuNames.INDEX])} />
          </S.MenuItemContent>
        </S.MenuItem>

        <S.MenuItem
          onClick={() => {
            redirect({history, dispatch, route: MenuNames.COURSES});
          }}
          open={open}
        >
          <S.MenuItemContent
            active={isCurrentPage([MenuNames.COURSES, MenuNames.COURSE_DETAIL])}
          >
            {t(`menu.${MenuNames.COURSES}`)}
            <S.MenuUnderline
              active={isCurrentPage([
                MenuNames.COURSES,
                MenuNames.COURSE_DETAIL,
              ])}
            />
          </S.MenuItemContent>
        </S.MenuItem>

        <S.MenuItem
          onClick={() => {
            redirect({history, dispatch, route: MenuNames.TEAM});
          }}
          open={open}
        >
          <S.MenuItemContent active={isCurrentPage([MenuNames.TEAM])}>
            {t(`menu.${MenuNames.TEAM}`)}
            <S.MenuUnderline active={isCurrentPage([MenuNames.TEAM])} />
          </S.MenuItemContent>
        </S.MenuItem>

        <div onClick={() => dispatch(closeMenu())}>
          <S.MenuItem onClick={() => focusFooterRef()} open={open}>
            <S.MenuItemContent active={isCurrentPage([MenuNames.CONTACT])}>
              {t(`menu.${MenuNames.CONTACT}`)}
              <S.MenuUnderline active={isCurrentPage([MenuNames.CONTACT])} />
            </S.MenuItemContent>
          </S.MenuItem>
        </div>
      </>
    );
  };

  return (
    <S.Menu id={'menu-anchor'}>
      <S.MenuItemsWrapper>
        <S.Logo
          onClick={() => {
            redirect({history, dispatch, route: MenuNames.INDEX});
          }}
        >
          <img
            width={1024}
            height={185}
            alt="menu-logo"
            src={images.components.menu.logo}
          />
        </S.Logo>

        <FontAwesomeIcon
          onClick={() => dispatch(toggleMenu())}
          color={colors.darkBlue}
          icon={open ? faXmark : faBars}
        />

        <S.MenuItems>{renderMenuItems()}</S.MenuItems>
      </S.MenuItemsWrapper>

      <S.MenuItemsResponsive open={open}>
        {renderMenuItems()}
      </S.MenuItemsResponsive>
    </S.Menu>
  );
};

export default translate()(Menu);

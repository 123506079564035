export const layers = {
  menu: 2,
  float: 1,
  texture: 0,
};

export const colors = {
  white: '#FFFFFF',
  darkBlue: '#16245C',
  darkerBlue: '#17255D',
  softBlue: '#E4E7F2',
  softerBlue: '#FAFCFF',
  lightBlue: '#697399',
  orange: '#E73A1E',
  darkOrange: '#D1260A',
  facebook: '#4267B2',
  twitter: '#1DA1F2',
  linkedin: '#0077B5',
  youtube: '#FF0000',
  whatsapp: '#25D366',
  instagram: '#8A3AB9',
  website: 'blue',
  grey: 'grey',
};

export const fonts = {
  justSans: 'JUST Sans',
  justSansBold: 'JUST Sans Bold',
  nesiota: 'Nesiota',
};

export const images = {
  pages: {
    home: {
      banner: '/images/pages/home/banner.png',
      logoTexture: '/images/pages/home/logo-texture.png',
      wavesMobile: '/images/pages/home/waves_mobile.png',
      waves: '/images/pages/home/waves.png',
      wheelCircle: '/images/pages/home/wheel-circle.png',
      wheelCore: '/images/pages/home/wheel-core.png',
      smallCard: {
        sustainable: '/images/pages/home/small-card/sustainable.png',
        healthy: '/images/pages/home/small-card/healthy.png',
        mobility: '/images/pages/home/small-card/mobility.png',
        content: '/images/pages/home/small-card/content.png',
        audience: '/images/pages/home/small-card/audience.png',
        solutions: '/images/pages/home/small-card/solutions.png',
      },
    },
    courseDetails: {
      arrow: '/images/pages/course-detail/arrow.png',
      smallCard: {
        citizens: '/images/pages/course-detail/small-card/citizens.png',
        students: '/images/pages/course-detail/small-card/students.png',
        professionals:
          '/images/pages/course-detail/small-card/professionals.png',
        activists: '/images/pages/course-detail/small-card/activists.png',
        publicManagers:
          '/images/pages/course-detail/small-card/public-managers.png',
      },
      footerWaves: {
        bg: '/images/pages/course-detail/wave_footer.png',
        bgMobile: '/images/pages/course-detail/wave_footer_mobile.png',
      },
    },
  },
  components: {
    footer: {
      bg: '/images/components/footer/bg.png',
      logo: '/images/components/footer/logo.png',
      wavesMobile: '/images/components/footer/waves_mobile.png',
      waves: '/images/components/footer/waves.png',
    },
    menu: {
      logo: '/images/components/menu/logo.png',
      bg: '/images/components/menu/bg.png',
      bgMobile: '/images/components/menu/bg-mobile.png',
    },
    teamCard: {
      logo: '/images/components/team-card/logo.png',
      logoBlue: '/images/components/team-card/logo-blue.png',
    },
    titleWaves: {
      bg: '/images/components/title-waves/bg.png',
      bgMobile: '/images/components/title-waves/bg_mobile.png',
    },
  },
};

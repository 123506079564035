import {AxiosInstance} from 'axios';

import {Languages} from '../../../../enums/languages';
import {AxiosFactory} from '../../../factories/axios-factory';
import {CollectionDto} from '../../dtos/collection-dto';
import {PaginationDto} from '../../dtos/pagination-dto';
import {TeamDto} from './dtos/team-dto';

export class TeamGateway {
  async find(dto?: {ids: number[]}): Promise<CollectionDto<TeamDto>[]> {
    const response = await this.axiosInstance.get<PaginationDto<TeamDto>>('', {
      params: {
        locale: this.locale,
        populate: '*',
        sort: ['rank'],
        filters: {
          id: {
            $in: dto ? dto.ids : [],
          },
        },
      },
    });

    return response.data.data;
  }

  constructor(
    private readonly axiosInstance: AxiosInstance,
    private readonly locale: Languages
  ) {}

  static make(locale: Languages): TeamGateway {
    const axiosInstance = AxiosFactory.make(
      `${process.env.REACT_APP_STRAPI_BASE_URL}/teams`
    );

    return new TeamGateway(axiosInstance, locale);
  }
}

import {useEffect, useRef, useState} from 'react';
import {useTranslate} from 'react-polyglot';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import {CollectionDto} from '../../../core/apis/api-strapi/dtos/collection-dto';
import {CourseGateway} from '../../../core/apis/api-strapi/gateways/course-gateway/course-gateway';
import {CourseDto} from '../../../core/apis/api-strapi/gateways/course-gateway/dtos/course-dto';
import {TeamDto} from '../../../core/apis/api-strapi/gateways/team-gateway/dtos/team-dto';
import {TeamGateway} from '../../../core/apis/api-strapi/gateways/team-gateway/team-gateway';
import {MenuNames} from '../../../core/enums/menu-names';
import {redirect} from '../../../core/services/link-routes';
import {goToPage} from '../../../infrastructure/redux/slices/menu-slice';
import {RootState} from '../../../infrastructure/redux/store';
import Button from '../../components/button';
import CoursesGallery from '../../components/courses-gallery';
import TeamCard from '../../components/team-card';
import Texture from '../../components/texture';
import Title from '../../components/title';
import {colors} from '../../styles/global';
import CourseContents from './components/course-contents';
import CourseDescription from './components/course-description';
import CourseLearn from './components/course-learn';
import CoursePrerequisite from './components/course-prerequisite';
import CoursePrice from './components/course-price';
import CourseTarget from './components/course-target';
import * as S from './style';

const CourseDetail = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const {id} = useParams<{id: string}>();

  const [course, setCourse] = useState<CollectionDto<CourseDto>>();
  const [team, setTeam] = useState<CollectionDto<TeamDto>[]>([]);

  const locale = useSelector(
    (state: RootState) => state.locale.currentLanguage
  );

  const [height, setHeight] = useState(0);

  const textureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(goToPage(MenuNames.COURSES));

    window.scrollTo(0, 0);

    textureRef.current && setHeight(textureRef.current.clientHeight);

    CourseGateway.make(locale)
      .findById(Number(id))
      .then(course => {
        setCourse(course);

        return TeamGateway.make(locale).find({
          ids: course.attributes.teams.data.map(d => d.id),
        });
      })
      .then(team => {
        setTeam(team);
      });
  }, [id]);

  return course ? (
    <>
      <S.DetailsPage>
        <S.DetailsPageContent ref={textureRef}>
          <Texture height={height} />

          <S.DetailsHeaderSection>
            <CoursePrice {...course.attributes.callToAction} />
          </S.DetailsHeaderSection>

          <S.DetailsContentSection>
            <Title
              title={course.attributes.title}
              labelColor={colors.orange}
              dividerColor={colors.darkBlue}
            />

            <CourseDescription
              image={course.attributes.image}
              description={course.attributes.description}
            />
          </S.DetailsContentSection>

          <S.DetailsContentSection>
            <Title
              title={t('courseDetail.description.learn')}
              labelColor={colors.orange}
              dividerColor={colors.darkBlue}
            />

            <CourseLearn learn={course.attributes.learn} />
          </S.DetailsContentSection>

          <S.DetailsContentSection>
            <Title
              title={t('courseDetail.description.target')}
              labelColor={colors.orange}
              dividerColor={colors.darkBlue}
            />

            <CourseTarget
              target={course.attributes.target}
              targetCards={[
                course.attributes.activists && 'activists',
                course.attributes.citizens && 'citizens',
                course.attributes.professionals && 'professionals',
                course.attributes.publicManagers && 'publicManagers',
                course.attributes.students && 'students',
              ]}
            />
          </S.DetailsContentSection>

          <S.DetailsContentSection>
            <Title
              title={t('courseDetail.description.content')}
              labelColor={colors.white}
              dividerColor={colors.orange}
            />

            <CourseContents content={course.attributes.content} />
          </S.DetailsContentSection>

          <S.DetailsContentSection>
            <Title
              title={t('courseDetail.description.requirements')}
              labelColor={colors.white}
              dividerColor={colors.orange}
            />
          </S.DetailsContentSection>

          <S.DetailsContentSection>
            <CoursePrerequisite requirement={course.attributes.requirements} />
          </S.DetailsContentSection>
        </S.DetailsPageContent>

        <S.DetailsFooterSection />
      </S.DetailsPage>

      <S.DetailsUnderpage>
        <S.TeamSection>
          {team.map((collection, index) => {
            return (
              <TeamCard
                key={index}
                occupation={collection.attributes.occupation}
                name={collection.attributes.name}
                description={collection.attributes.description}
                image={collection.attributes.image}
                imageMask={collection.attributes.imageMask}
                socialMedias={collection.attributes.socialMedias}
                email={collection.attributes.email}
              />
            );
          })}

          <div
            onClick={() => {
              redirect({history, dispatch, route: MenuNames.TEAM});
            }}
          >
            <Button label={t('courseDetail.team.teamButton')} />
          </div>
        </S.TeamSection>

        <CoursesGallery
          title={t('courseDetail.courses.courseButton')}
          labelColor={colors.orange}
          dividerColor={colors.darkBlue}
          ownId={course.id}
        />
      </S.DetailsUnderpage>
    </>
  ) : (
    <></>
  );
};

export default CourseDetail;

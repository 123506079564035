import {AxiosInstance} from 'axios';

import {Languages} from '../../../../enums/languages';
import {AxiosFactory} from '../../../factories/axios-factory';
import {CollectionDto} from '../../dtos/collection-dto';
import {SingleDto} from '../../dtos/single-dto';
import {SubscriptionDto} from './dtos/subscription-dto';

export class SubscriptionGateway {
  async find(): Promise<CollectionDto<SubscriptionDto>> {
    const response = await this.axiosInstance.get<SingleDto<SubscriptionDto>>(
      '',
      {
        params: {
          locale: this.locale,
          populate: '*',
        },
      }
    );

    return response.data.data;
  }

  constructor(
    private readonly axiosInstance: AxiosInstance,
    private readonly locale: Languages
  ) {}

  static make(locale: Languages): SubscriptionGateway {
    const axiosInstance = AxiosFactory.make(
      `${process.env.REACT_APP_STRAPI_BASE_URL}/subscription`
    );

    return new SubscriptionGateway(axiosInstance, locale);
  }
}
